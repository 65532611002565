<template>
  <b-container fluid>
    <slot v-if="$i18n.locale === 'en'" >
      <BARCCertificateViewBn :id="id" :circular_memo_no="circular_memo_no"/>
    </slot>
    <slot v-else>
      <BARCCertificateViewBn :id="id" :circular_memo_no="circular_memo_no"/>
  </slot>
  </b-container>
</template>
<script>
// import BARCCertificateViewEn from './BARCCertificateViewEn'
import BARCCertificateViewBn from './BARCCertificateViewBn'

export default {
  components: {
    BARCCertificateViewBn
  },
  data () {
    return {
      id: this.$route.query.id,
      circular_memo_no: this.$route.query.circular_memo_no
    }
  }
}
</script>
